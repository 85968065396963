.iframe-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .iframe-modal-content {
    position: relative;
    width: 80%;
    max-width: 800px;
    height: 80%;
    background: white;
    border-radius: 8px;
    overflow: hidden;
  }

  .iframe-modal iframe {
    width: 100%;
    height: 100%;
  }

  .iframe-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: black;
    cursor: pointer;
  }

  .iframe-modal-close:hover {
    color: red;
  }
