.App {
  text-align: center;
  position: relative;
  min-height: 100vh;
  background-color: #000000;
  background-image: url('./assets/backgroundSixpack.png');
  background-size: cover;
  background-position: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(5px);
  z-index: 1; /* Para asegurarte de que la superposición esté sobre el contenido */
}

#content {
  position: relative;
  z-index: 2; /* Para asegurarte de que el contenido esté sobre la superposición */
}

/* Definir la fuente personalizada */
@font-face {
  font-family: 'MyCustomFont';
  src: url('./assets/fonts/BricolageGrotesque48pt-Bold-BF648bd5786c204.ttf') format('truetype'),
       url('./assets/fonts/BricolageGrotesque48pt-ExtraBold-BF648bd57a07c71.ttf') format('truetype'),
       url('./assets/fonts/Flama\ Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Aplicar la fuente personalizada */
body {
  font-family: 'MyCustomFont', sans-serif; /* Aplica la fuente personalizada al cuerpo del documento */
}

h1, h2 {
  color: #d39c1a;
  font-family: 'MyCustomFont', serif; /* Aplica la fuente personalizada a los encabezados */
}

.logo img {
  margin-top: 5%;
  height: auto;
  max-height: 30vh; /* Asegura que el logo no sea demasiado grande en pantallas grandes */
}

.message {
  color: white;
  font-size: 1.2em;
  margin-top: 20px;
  margin-bottom: 20px; /* Ajusta para dejar espacio para el botón */
  line-height: 1.5;
  padding: 0 10%; /* Agrega un padding horizontal para mejorar la legibilidad en pantallas pequeñas */
}

.register-button {
  font-size: 1.2em;
  font-family: 'MyCustomFont', serif;
  background-color: #475826; /* Color de fondo del botón */
  border-color: #d39c1a;
  color: #d39c1a; /* Color del texto del botón */
  padding: 10px 20px; /* Tamaño del botón */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer;
  transition: background-color 0.3s; /* Transición suave al pasar el cursor */
  margin-bottom: 20px; /* Espacio debajo del botón */
}

.register-button:hover {
  color: #000;
  background-color: #ffffff; /* Color de fondo del botón al pasar el cursor */
}

footer {
  position: fixed;
  bottom: 0; /* Fija el pie de página al fondo de la ventana */
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo semi-transparente para el pie de página */
  padding: 10px 0;
}

.social-icons {
  margin-top: 10px;
}

.social-icons a {
  color: white;
  font-size: 24px; /* Tamaño del ícono */
  margin: 0 15px; /* Mayor separación entre íconos */
  text-decoration: none;
}

.social-icons a:hover {
  color: #ccc; /* Cambia el color cuando se pase el cursor sobre el ícono */
}

/* Media Queries para pantallas móviles */
@media (max-width: 768px) {
  .logo img {
    margin-top: 30%;
    max-height: 20vh; /* Reduce el tamaño del logo en pantallas móviles */
  }

  .message {
    font-size: 1em; /* Reduce el tamaño del texto del mensaje en pantallas móviles */
    padding: 0 5%; /* Reduce el padding horizontal en pantallas móviles */
  }

  .register-button {
    padding: 8px 16px; /* Reduce el padding del botón en pantallas móviles */
    font-size: 0.9em; /* Reduce el tamaño del texto del botón en pantallas móviles */
  }

  .social-icons a {
    font-size: 20px; /* Reduce el tamaño de los íconos en pantallas móviles */
    margin: 0 8px; /* Reduce la separación entre íconos en pantallas móviles */
  }
}

@media (max-width: 480px) {
  .logo img {
    margin-top: 30%;
    max-height: 20vh; /* Reduce el tamaño del logo en pantallas móviles */
  }

  .message {
    font-size: 0.9em; /* Reduce aún más el tamaño del texto en pantallas más pequeñas */
  }

  .register-button {
    padding: 6px 12px; /* Ajusta el padding del botón para pantallas muy pequeñas */
    font-size: 0.8em; /* Ajusta el tamaño del texto del botón para pantallas muy pequeñas */
  }

  .social-icons a {
    font-size: 18px; /* Ajusta el tamaño de los íconos para pantallas muy pequeñas */
    margin: 0 6px; /* Ajusta la separación entre íconos para pantallas muy pequeñas */
  }
}
