.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Fondo oscuro semi-transparente */
    backdrop-filter: blur(5px);
  }

  .modal-content {
    background-color: #ffffff;
    padding: 20px;
    /* border: 1px solid #888; */
    width: 100%;
    max-width: 500px;
    text-align: center;
    border-radius: 8px;
    position: relative;
    z-index: 1001; /* Asegurarse de que esté por encima del fondo */
  }

  .modal-content h2 {
    margin-top: 0;
    font-size: xx-large;
  }

  .modal-content button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .modal-content button:first-of-type {
    background-color: #4CAF50; /* Verde para Sí */
    color: white;
  }

  .modal-content button:last-of-type {
    background-color: #f44336; /* Rojo para No */
    color: white;
  }
